import { ActionTypes } from "../constants/ActionTypes";
import { base_url } from "../../constants";
import Axios from "axios";

export const setLoanApplications = (application) => {
  return {
    type: ActionTypes.SET_APPLICATION,
    payload: application,
  };
};

//------------------------- Loan Details List --------------------

export const LoanDetailsList = (navigateCallback) => (dispatch) => {
  dispatch(LoanDetailsListLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(`${base_url}/api/v1/webapp/getAllLoans`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAN_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      } else {
        dispatch({
          type: ActionTypes.LOAN_DETAILS_FAIL,
        });
      }
    });
};

//------------------------- Loan Details List Loading  --------------------

export const LoanDetailsListLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_DETAILS_LOADING,
  });
};

//------------------------- Loan Details Branch Specific List --------------------

export const LoanDetailsListBranches =
  (code, navigateCallback) => (dispatch) => {
    dispatch(LoanDetailsListBranchesLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.get(
      `${base_url}/api/v1/loanApp/getAllLoansByBranch?branch_code=${code}`,
      config
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.LOAN_DETAILS_BRANCH_SPECIFIC_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        } else {
          dispatch({
            type: ActionTypes.LOAN_DETAILS_BRANCH_SPECIFIC_FAIL,
          });
        }
      });
  };

//------------------------- Loan Details Branch Specific List Loading  --------------------

export const LoanDetailsListBranchesLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_DETAILS_BRANCH_SPECIFIC_LOADING,
  });
};

//------------------------- Loan Details By ID --------------------

export const LoanDetailsById = (id, navigateCallback) => (dispatch) => {
  dispatch(LoanDetailsByIdLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(
    `${base_url}/api/v1/webapp/getAllLoanDetailsByLoanId?loan_id=${id}`,
    config
  )
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAN_DETAILS_BY_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      } else {
        dispatch({
          type: ActionTypes.LOAN_DETAILS_BY_ID_FAIL,
        });
      }
    });
};

//------------------------- Loan Details By ID Loading  --------------------

export const LoanDetailsByIdLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_DETAILS_BY_ID_LOADING,
  });
};

//------------------------- Loan Verification ---------------------------

export const LoanVerification =
  (role_code, emp_code, remarks, decision, loan_id, navigateCallback) =>
  (dispatch) => {
    dispatch(LoanVerificationLoading());
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const data = {
      user_role_code: role_code,
      employee_code: emp_code,
      remarks: remarks,
      decision: decision,
      loan_id: loan_id,
    };
    Axios.post(
      `${base_url}/api/v1/webapp/saveLoanVerificationResponse`,
      data,
      config
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.VERIFICATION_LOAN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        } else {
          dispatch({
            type: ActionTypes.VERIFICATION_LOAN_FAIL,
          });
        }
      });
  };

//------------------------- Loan Verification Loading  --------------------

export const LoanVerificationLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.VERIFICATION_LOAN_LOADING,
  });
};

//------------------------- Pending Loan Details List --------------------

export const PendingLoans = (navigateCallback) => (dispatch) => {
  dispatch(PendingLoansLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(`${base_url}/api/v1/webapp/getPendingLoans`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.PENDING_LOAN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      } else {
        dispatch({
          type: ActionTypes.PENDING_LOAN_FAIL,
        });
      }
    });
};

//------------------------- Pending Loans List Loading  --------------------

export const PendingLoansLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.PENDING_LOAN_LOADING,
  });
};

//------------------------- Digital Loan Agreement PDF --------------------

export const LoanAgreementPDF = (loanid, navigateCallback) => (dispatch) => {
  dispatch(LoanAgreementPDFLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(
    `${base_url}/api/v1/webapp/getDigitalLoanAgreement?loan_id=${loanid}`,
    config
  )
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAN_AGREEMENT_PDF_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      } else {
        dispatch({
          type: ActionTypes.LOAN_AGREEMENT_PDF_FAIL,
        });
      }
    });
};

//------------------------- Physical Loan Agreement PDF --------------------

export const LoanAgreementPhysicalPDF =
  (loanid, navigateCallback) => (dispatch) => {
    dispatch(LoanAgreementPhysicalPDFLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.get(
      `${base_url}/api/v1/webapp/getPhysicalLoanAgreement?loan_id=${loanid}`,
      config
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.LOAN_AGREEMENT_PDF_PHYSICAL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        } else {
          dispatch({
            type: ActionTypes.LOAN_AGREEMENT_PDF_PHYSICAL_FAIL,
          });
        }
      });
  };

//------------------------- Digital Loan Agreement PDF Loading  --------------------

export const LoanAgreementPDFLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_AGREEMENT_PDF_LOADING,
  });
};

//------------------------- Physical Loan Agreement PDF Loading  --------------------

export const LoanAgreementPhysicalPDFLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_AGREEMENT_PDF_PHYSICAL_LOADING,
  });
};

//------------------------- Get Disbursal List --------------------

export const DisburseListAPI = (loanid, navigateCallback) => (dispatch) => {
  dispatch(DisburseListAPILoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(
    `${base_url}/api/v1/webapp/getPreDisbursalCheckList?loan_id=${loanid}`,
    config
  )
    .then((response) => {
      dispatch({
        type: ActionTypes.DISBURSE_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      } else {
        dispatch({
          type: ActionTypes.DISBURSE_LIST_FAIL,
        });
      }
    });
};

//------------------------- Get Disbursal List Loading  --------------------

export const DisburseListAPILoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.DISBURSE_LIST_LOADING,
  });
};

//------------------------- Disburse Loan --------------------

// export const DisburseLoan = (loanid,rows) => (dispatch) => {
//   dispatch(DisburseLoanLoading());
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
//     },
//   };
//   const data = {
//     loan_id: loanid,
//     check_list_response: rows
//   };
//   Axios.post(`${base_url}/api/v1/customer/SavePreDisbursalCheckList`,data, config)
//     .then((response) => {
//       dispatch({
//         type: ActionTypes.SUBMIT_DISBURSE_SUCCESS,
//         payload: response.data,
//       });
//     })
//     .catch((error) => {
//       dispatch({
//         type: ActionTypes.SUBMIT_DISBURSE_FAIL,
//       });
//     });
// };

// //------------------------- Get Disbursal List Loading  --------------------

// export const DisburseLoanLoading = () => (dispatch) => {
// dispatch({
//   type: ActionTypes.SUBMIT_DISBURSE_LOADING,
// });
// };

//-------------------------- Get Loan Repayment Schedule ---------------------

export const GetLoanRepaymentSchedule = (loanid) => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(
    `${base_url}/api/v1/loanApp/getRepaymentSchedule?loan_id=${loanid}`,
    config
  )
    .then((response) => {
      dispatch({
        type: ActionTypes.REPAYMENT_SCHEDULE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.REPAYMENT_SCHEDULE_FAIL,
      });
    });
};

//-------------------------- Get Payment History ---------------------

export const GetPaymentHistory = (loanid) => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(
    `${base_url}/api/v1/loanApp/getPaymentHistory?loan_id=${loanid}`,
    config
  )
    .then((response) => {
      dispatch({
        type: ActionTypes.PAYMENT_HISTORY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.PAYMENT_HISTORY_FAIL,
      });
    });
};

//------------------------- Get Disbursal List --------------------

export const DisburseButton =
  (loanid, checklist, navigateCallback) => (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const data = {
      loan_id: loanid,
      disbursal_checklist_json: checklist,
    };
    Axios.post(`${base_url}/api/v1/webapp/disburseLoan`, data, config)
      .then((response) => {
        dispatch({
          type: ActionTypes.DISBURSE_BUTTON_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        } else {
          dispatch({
            type: ActionTypes.DISBURSE_BUTTON_FAIL,
          });
        }
      });
  };
//------------------------- Loan Verification JLG---------------------------

export const LoanVerificationJLG =
  (
    role_code,
    emp_code,
    remarks,
    decision,
    loan_id,
    userInfo,
    navigateCallback
  ) =>
  (dispatch) => {
    dispatch(LoanVerificationLoading());
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const data = {
      user_role_code: role_code,
      employee_code: emp_code,
      remarks: remarks,
      decision: decision,
      loan_id: loan_id,
      input_data_qde: userInfo,
    };
    Axios.post(
      `${base_url}/api/v2/webapp/saveJlglLoanVerificationResponse`,
      data,
      config
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.VERIFICATION_LOAN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        } else {
          dispatch({
            type: ActionTypes.VERIFICATION_LOAN_FAIL,
          });
        }
      });
  };
//------------------------- Pending Loan Details List Branch Manager --------------------

export const PendingLoansBM = (navigateCallback) => (dispatch) => {
  dispatch(PendingLoansLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(`${base_url}/api/v2/webapp/getPendingLoansByBM`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.PENDING_LOAN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      } else {
        dispatch({
          type: ActionTypes.PENDING_LOAN_FAIL,
        });
      }
    });
};
