import React from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";


const Invalid = () => {

  return (
    <Grid container direction="column" sx={{ height: "100vh" }}>
      {/* Main content area */}
      <Grid item container sx={{ marginTop: "64px", flexGrow: 1 }}>
      </Grid>
    </Grid> 
  );
};

export default Invalid;