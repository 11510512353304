import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Login/Index";
import LoanDetailsQDE from "./Components/LoanDetails/loanDetailsQDE";
import LoanDetailsBM from "./Components/LoanDetails/loanDetailsBM";
import PendingActions from "./Components/PendingActions/pendingActions";
import PendingLanding from "./Components/PendingActions/pendingLanding";
import PendingLandingHO from "./Components/PendingActions/pendingLandingHO";
import LoanDetailsHO from "./Components/LoanDetails/loanDetailsHO";
import DisburseList from "./Components/PendingActions/disburseList";
import Invalid from "./Components/Common/invalid";
import DetailsPage from "./Components/LoanDetails/detailsPage";
import LoanDetailsQDEA from "./Components/LoanDetails/loanDetailsQDEA";
import LoanDetailsQDEM from "./Components/LoanDetails/loanDetailsQDEM";
import PendingActionsQDEM from "./Components/PendingActions/pendingActionsQDEM";
import PendingLandingBM from "./Components/PendingActions/pendingLandingBM";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/LoanDetailsQDE" element={<LoanDetailsQDE />} />
        <Route path="/LoanDetailsQDEM" element={<LoanDetailsQDEM />} />
        <Route path="/LoanDetailsQDEA" element={<LoanDetailsQDEA />} />
        <Route path="/LoanDetailsBM" element={<LoanDetailsBM />} />
        <Route path="/LoanDetailsHO" element={<LoanDetailsHO />} />
        <Route path="/pendingActions" element={<PendingActions />} />
        <Route path="/pendingActionsQDEM" element={<PendingActionsQDEM />} />
        <Route path="/pendingLandingBM" element={<PendingLandingBM />} />
        <Route path="/pendingLanding" element={<PendingLanding />} />
        <Route path="/pendingLandingHO" element={<PendingLandingHO />} />
        <Route path="/DisburseList" element={<DisburseList />} />
        <Route path="/DetailsPage" element={<DetailsPage />} />
        <Route path="/Invalid" element={<Invalid />} />
      </Routes>
    </Router>
  );
}

export default App;
