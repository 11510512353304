export const ActionTypes = {
    SET_APPLICATION: "SET_APPLICATION",

    LOGIN_DETAILS_SUCCESS: "LOGIN_DETAILS_SUCCESS",

    LOAN_DETAILS_LOADING: "LOAN_DETAILS_LOADING",
    LOAN_DETAILS_SUCCESS: "LOAN_DETAILS_SUCCESS",
    LOAN_DETAILS_FAIL: "LOAN_DETAILS_FAIL",

    LOAN_DETAILS_BRANCH_SPECIFIC_LOADING: "LOAN_DETAILS_BRANCH_SPECIFIC_LOADING",
    LOAN_DETAILS_BRANCH_SPECIFIC_SUCCESS: "LOAN_DETAILS_BRANCH_SPECIFIC_SUCCESS",
    LOAN_DETAILS_BRANCH_SPECIFIC_FAIL: "LOAN_DETAILS_BRANCH_SPECIFIC_FAIL",

    LOAN_DETAILS_BY_ID_LOADING: "LOAN_DETAILS_BY_ID_LOADING",
    LOAN_DETAILS_BY_ID_SUCCESS: "LOAN_DETAILS_BY_ID_SUCCESS",
    LOAN_DETAILS_BY_ID_FAIL: "LOAN_DETAILS_BY_ID_FAIL",

    VERIFICATION_LOAN_LOADING: "VERIFICATION_LOAN_LOADING",
    VERIFICATION_LOAN_SUCCESS: "VERIFICATION_LOAN_SUCCESS",
    VERIFICATION_LOAN_FAIL: "VERIFICATION_LOAN_FAIL",

    PENDING_LOAN_LOADING: "PENDING_LOAN_LOADING",
    PENDING_LOAN_SUCCESS: "PENDING_LOAN_SUCCESS",
    PENDING_LOAN_FAIL: "PENDING_LOAN_FAIL",

    LOAN_AGREEMENT_PDF_LOADING: "LOAN_AGREEMENT_PDF_LOADING",
    LOAN_AGREEMENT_PDF_SUCCESS: "LOAN_AGREEMENT_PDF_SUCCESS",
    LOAN_AGREEMENT_PDF_FAIL: "LOAN_AGREEMENT_PDF_FAIL",

    LOAN_AGREEMENT_PDF_PHYSICAL_LOADING: "LOAN_AGREEMENT_PDF_PHYSICAL_LOADING",
    LOAN_AGREEMENT_PDF_PHYSICAL_SUCCESS: "LOAN_AGREEMENT_PDF_PHYSICAL_SUCCESS",
    LOAN_AGREEMENT_PDF_PHYSICAL_FAIL: "LOAN_AGREEMENT_PDF_PHYSICAL_FAIL",

    DISBURSE_LIST_LOADING: "DISBURSE_LIST_LOADING",
    DISBURSE_LIST_SUCCESS: "DISBURSE_LIST_SUCCESS",
    DISBURSE_LIST_FAIL: "DISBURSE_LIST_FAIL",

    SUBMIT_DISBURSE_LOADING: "SUBMIT_DISBURSE_LOADING",
    SUBMIT_DISBURSE_SUCCESS: "SUBMIT_DISBURSE_SUCCESS",
    SUBMIT_DISBURSE_FAIL: "SUBMIT_DISBURSE_FAIL",

    REPAYMENT_SCHEDULE_LOADING: "REPAYMENT_SCHEDULE_LOADING",
    REPAYMENT_SCHEDULE_SUCCESS: "REPAYEMNT_SCHEDULE_SUCCESS",
    REPAYMENT_SCHEDULE_FAIL: "REPAYMENT_SCHEDULE_FAIL",

    PAYMENT_HISTORY_LOADING: "PAYMENT_HISTORY_LOADING",
    PAYMENT_HISTORY_SUCCESS: "PAYMENT_HISTORY_SUCCESS",
    PAYMENT_HISTORY_FAIL: "PAYMENT_HISTORY_FAIL",

    DISBURSE_BUTTON_LOADING: "DISBURSE_BUTTON_LOADING",
    DISBURSE_BUTTON_SUCCESS: "DISBURSE_BUTTON_SUCCESS",
    DISBURSE_BUTTON_FAIL: "DISBURSE_BUTTON_FAIL",
};