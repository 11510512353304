// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.triangle {
    width: 0;
    height: 0;
    border-left: 100px solid transparent; /* Adjust width of the triangle */
    border-bottom: 100px solid orange; /* Adjust height and color of the triangle */
    position: absolute;
    top: 0; /* Position from the top */
    right: 15px; /* Position from the right */
    transform: rotate(270deg);
  }`, "",{"version":3,"sources":["webpack://./src/Components/Login/login.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,SAAS;IACT,oCAAoC,EAAE,iCAAiC;IACvE,iCAAiC,EAAE,4CAA4C;IAC/E,kBAAkB;IAClB,MAAM,EAAE,0BAA0B;IAClC,WAAW,EAAE,4BAA4B;IACzC,yBAAyB;EAC3B","sourcesContent":[".triangle {\n    width: 0;\n    height: 0;\n    border-left: 100px solid transparent; /* Adjust width of the triangle */\n    border-bottom: 100px solid orange; /* Adjust height and color of the triangle */\n    position: absolute;\n    top: 0; /* Position from the top */\n    right: 15px; /* Position from the right */\n    transform: rotate(270deg);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
