import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PendingIcon from "@mui/icons-material/Pending";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const SideNav = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          top: "82px", // Adjust this value to match the height of your header
          height: "calc(100% - 64px)", // Adjust this value to match the height of your header
          backgroundColor: "#184A90", // Blue background color
          color: "#ffffff", // White text color
        },
      }}
    >
      <List>
        {localStorage.getItem("Role") == "BM" ? (
          <ListItem
            button
            component={Link}
            to="/LoanDetailsBM"
            sx={{ justifyContent: "center", mt: 4 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Status" />
          </ListItem>
        ) : localStorage.getItem("Role") == "QDE" ? (
          <ListItem
            button
            component={Link}
            to="/LoanDetailsQDE"
            sx={{ justifyContent: "center", mt: 4 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Status" />
          </ListItem>
        ) : localStorage.getItem("Role") == "QDEM" ? (
          <ListItem
            button
            component={Link}
            to="/LoanDetailsQDEM"
            sx={{ justifyContent: "center", mt: 4 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Status" />
          </ListItem>
        ) : localStorage.getItem("Role") == "QDEA" ? (
          <ListItem
            button
            component={Link}
            to="/LoanDetailsQDEA"
            sx={{ justifyContent: "center", mt: 4 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Status" />
          </ListItem>
        ) : localStorage.getItem("Role") == "HO" ? (
          <ListItem
            button
            component={Link}
            to="/LoanDetailsHO"
            sx={{ justifyContent: "center", mt: 4 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Status" />
          </ListItem>
        ) : localStorage.getItem("Role") == "A" ? (
          <ListItem
            button
            component={Link}
            to="/LoanDetailsQDE"
            sx={{ justifyContent: "center", mt: 4 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Status" />
          </ListItem>
        ) : (
          <ListItem
            button
            component={Link}
            to="/Invalid"
            sx={{ justifyContent: "center", mt: 4 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Loan Status" />
          </ListItem>
        )}

        {localStorage.getItem("Role") == "BM" ? (
          <ListItem
            button
            component={Link}
            to="/PendingLandingBM"
            sx={{ justifyContent: "center", mt: 1 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending Actions" />
          </ListItem>
        ) : localStorage.getItem("Role") == "QDE" ? (
          <ListItem
            button
            component={Link}
            to="/PendingLanding"
            sx={{ justifyContent: "center", mt: 1 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending Actions" />
          </ListItem>
        ) : localStorage.getItem("Role") == "HO" ? (
          <ListItem
            button
            component={Link}
            to="/PendingLandingHO"
            sx={{ justifyContent: "center", mt: 1 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending Actions" />
          </ListItem>
        ) : localStorage.getItem("Role") == "A" ? (
          <ListItem
            button
            component={Link}
            to="/PendingLanding"
            sx={{ justifyContent: "center", mt: 1 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending Actions" />
          </ListItem>
        ) : localStorage.getItem("Role") == "QDEA" ? (
          <ListItem
            button
            component={Link}
            to="/PendingLanding"
            sx={{ justifyContent: "center", mt: 1 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending Actions" />
          </ListItem>
        ) : localStorage.getItem("Role") == "QDEM" ? (
          <ListItem
            button
            component={Link}
            to="/PendingLanding"
            sx={{ justifyContent: "center", mt: 1 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending Actions" />
          </ListItem>
        ) : (
          <ListItem
            button
            component={Link}
            to="/Invalid"
            sx={{ justifyContent: "center", mt: 1 }}
          >
            <ListItemIcon sx={{ color: "#ffffff", minWidth: "40px" }}>
              <PendingIcon />
            </ListItemIcon>
            <ListItemText primary="Pending Actions" />
          </ListItem>
        )}
      </List>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "100vh", // Full viewport height
          p: 2, // Optional padding
        }}
      >
        <Button
          variant="contained"
          color="warning"
          startIcon={<LogoutIcon />}
          sx={{ m: 2, backgroundColor: "#FF5722", color: "#ffffff" }} // Orange button with white text
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
    </Drawer>
  );
};

export default SideNav;
