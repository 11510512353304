import React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, useDispatch } from "react-redux";
import PdfViewer from "../Common/pdfviewer";
import ReusableSnackbar from "../Common/timeoutSnackbar";
import ErrorSnackbar from "../Common/errorSnackbar";
import {
  LoanDetailsList,
  LoanDetailsListLoading,
  LoanDetailsById,
  LoanAgreementPDF,
} from "../../redux/actions/LoanActions";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import SideNav from "../Common/sideNav";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "../../redux/constants/ActionTypes";
import Headerkpm from "../Common/header";

const LoanDetailsQDEA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isloanAgreement, setisloanAgreement] = React.useState(false);
  const [loansfetched, setloansfetched] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [base64Pdf, setbase64Pdf] = React.useState(false);
  const handleLogout = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    setTimeout(() => {
      localStorage.removeItem("jwt_token");
      localStorage.clear();
      navigate("/");
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const pdfPrefix = "data:application/pdf;base64,";

  const loanDetailsLoading = useSelector(
    (state) => state.applicationReducer.loanDetailsLoading
  );

  const loanDetailsSuccess = useSelector(
    (state) => state.applicationReducer.loanDetailsSuccess
  );

  const loanDetailsFail = useSelector(
    (state) => state.applicationReducer.loanDetailsFail
  );

  const agreementPdfSuccess = useSelector(
    (state) => state.applicationReducer.agreementPdfSuccess
  );

  const agreementPdfFail = useSelector(
    (state) => state.applicationReducer.agreementPdfFail
  );

  const handleagreeclick = (id) => {
    try {
      dispatch(LoanAgreementPDF(id, handleLogout));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setisLoading(true);
    setisloanAgreement(true);
  };

  useEffect(() => {
    if (agreementPdfSuccess.data != undefined) {
      // setisloanAgreement(true);
      setisLoading(false);
    }
  }, [agreementPdfSuccess]);

  const handleDetailsClick = (loanid) => {
    // const fetchData = async () => {
    //   try {
    //      await dispatch(LoanDetailsById(loanid));
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    // fetchData();
    navigate("/DetailsPage", { state: { id: loanid } });
  };

  useEffect(() => {
    if (loanDetailsSuccess.data != undefined) {
      setloansfetched(true);
    }
  }, [loanDetailsSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(LoanDetailsList(handleLogout));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleCloseDialog = () => {
    setisloanAgreement(false);
  };
  return (
    <>
      <Grid container direction="column" sx={{ height: "100vh" }}>
        {/* Header */}
        <Grid item xs={12}>
          <CssBaseline />
          <AppBar position="fixed">
            <Headerkpm />
          </AppBar>
        </Grid>
        {/* Main content area */}
        <Grid item container sx={{ marginTop: "64px", flexGrow: 1 }}>
          {/* SideNav */}
          <Grid item xs={3}>
            <SideNav />
          </Grid>
          {/* Main content */}
          {loansfetched ? (
            <Grid item xs={9} sx={{ padding: 1.5, marginTop: "64px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Loan Details
              </Typography>
              {loanDetailsSuccess?.data?.map((loan) => (
                <Box
                  key={loan.loan_id}
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "40px",
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "4px solid blue",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    gutterBottom
                  >
                    {`Loan ${loan.loan_reference_id}`}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Application Details
                  </Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Loan Product: ${loan.loan_product}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Loan Amount: ${loan.loan_amount}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Loan Tenure: ${loan.loan_tenure}`}</Typography>
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Approval Details
                  </Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Eligibility Amount: ${loan.eligibility_amount}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Interest Rate: ${loan.interest_rate}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Tenure: ${loan.tenure}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Sanctioned Amount: ${loan.sanctioned_amount}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Loan Approval Status: ${loan.loan_approval_status}`}</Typography>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    gutterBottom
                  >{`Declined Reason: ${loan.declined_reason}`}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "auto",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleagreeclick(loan.loan_id)}
                      style={{ marginRight: "10px" }}
                    >
                      Loan Agreement
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handleDetailsClick(loan.loan_id)}
                      style={{ marginRight: "10px" }}
                    >
                      Loan Details
                    </Button>
                  </Box>
                </Box>
              ))}
              <Dialog
                open={isloanAgreement}
                onClose={handleCloseDialog}
                fullScreen
              >
                {!isLoading ? (
                  <>
                    <DialogTitle>Loan Agreement</DialogTitle>
                    <DialogContent
                      sx={{ flexGrow: 1, overflow: "auto", padding: 0 }}
                    >
                      <PdfViewer
                        base64Pdf={
                          agreementPdfSuccess?.data?.loan_agreement_base_64
                        }
                      />
                      {agreementPdfSuccess?.data?.is_verified ? (
                        <>
                          <Typography
                            sx={{
                              marginTop: "20px",
                              fontSize: "22px",
                              marginLeft: "20px",
                            }}
                          >
                            Live Capture
                          </Typography>
                          <img
                            src={
                              "data:image/jpeg;base64," +
                              agreementPdfSuccess?.data?.self_photo
                            }
                            alt="No Picture"
                            style={{
                              marginTop: "10px",
                              width: "200px",
                              height: "200px",
                              marginLeft: "80px",
                            }}
                          />
                        </>
                      ) : null}
                      <DialogActions>
                        <Button
                          onClick={handleCloseDialog}
                          sx={{
                            textTransform: "none",
                            marginBottom: "20px",
                            marginRight: "20px",
                            marginTop: "20px",
                          }}
                          variant="contained"
                          style={{
                            backgroundColor: "#C87619",
                            color: "white",
                            width: "20%",
                          }}
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </DialogContent>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                      position: "relative",
                    }}
                  >
                    <CircularProgress />
                    <Button
                      onClick={handleCloseDialog}
                      sx={{
                        textTransform: "none",
                        marginBottom: "20px",
                        marginRight: "20px",
                        marginTop: "20px",
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        backgroundColor: "#C87619",
                        color: "white",
                        width: "20%",
                      }}
                      variant="contained"
                    >
                      Close
                    </Button>
                  </Box>
                )}
              </Dialog>
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginLeft: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <ErrorSnackbar
        open={loanDetailsFail || agreementPdfFail}
        onClose={closeSnackbar}
      />
    </>
  );
};

export default LoanDetailsQDEA;
