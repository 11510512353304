import Axios from "axios";
import { base_url } from "./../../constants";
//------------------------Login API --------------------------------//
export const LoginUser = async (data) => {
  try {
    const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
    };
    const finaldata = {
        user_id: data.email,
        user_password: data.password,
    };
    const response = await Axios.post(`${base_url}/api/v1/users/login`, finaldata , config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

//------------------------Login OTP API --------------------------------//
export const LoginUserOtp = async (data,otp) => {
  try {
    const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
    };
    const finaldata = {
        user_id: data.email,
        otp: otp,
    };
    const response = await Axios.post(`${base_url}/api/v1/users/verifyLogin`, finaldata , config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};
