import React from "react";
import Grid from "@mui/material/Grid";
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Typography } from '@mui/material';
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import kpmlogo from "./../../Assets/kpmLogo.png"

const Headerkpm = () => {
  const navigate = useNavigate();
  const navigateTofirstPage = () => {
    if(localStorage.getItem("Role")=="QDE"){
      navigate("/LoanDetailsQDE");
    }else if(localStorage.getItem("Role")=="BM"){
      navigate("/LoanDetailsBM");
    }else if(localStorage.getItem("Role")=="HO"){
      navigate("/LoanDetailsHO");
    }else{
      navigate("/Invalid");
    }
    //window.location.reload();
  };

  const loginDetails= useSelector(
    (state) => state.applicationReducer.loginDetails
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={10}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
        }}
      >
        <img
          style={{ padding: "20px", cursor: "pointer" }}
          src={kpmlogo}
          onClick={navigateTofirstPage}
          alt="KPM logo"
        />
        <Box sx={{ width: "100%", p: 0, pt: 4 }}>
        <Typography variant="h5">Digital Lending Automation</Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
        }}
      >
        <Tooltip title="Profile" sx={{ mt: -1 }}>
          <IconButton
            color="#C87619"
            onClick={() => {
              //handleLogoutButton();
            }}
          >
            <PersonIcon style={{ color: 'orange' }} sx={{ fontSize: 30 }}/>
          </IconButton>
        </Tooltip>
        <div>
          <Typography sx={{ marginLeft:"14px", marginTop:"16px", fontSize:"16px" }}>{loginDetails.user_name}</Typography>
          <Typography sx={{ marginLeft:"14px", marginTop:"4px", fontSize:"16px" }}>{loginDetails.employee_code}</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Headerkpm;