import React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, useDispatch } from "react-redux";
import {
  LoanVerification,
  DisburseListAPI,
  DisburseLoan,
  DisburseButton,
} from "../../redux/actions/LoanActions";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import SideNav from "../Common/sideNav";
import ReusableSnackbar from "../Common/timeoutSnackbar";
import ErrorSnackbar from "../Common/errorSnackbar";
import { useNavigate, useLocation } from "react-router-dom";
import { ActionTypes } from "../../redux/constants/ActionTypes";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Headerkpm from "../Common/header";

const DisburseList = () => {
  const [disburseClicked, setDisburseClicked] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [showDisburseSnackbar, setShowDisburseSnackbar] = useState(false);
  const [disbursesnackbarMessage, setDisburseSnackbarMessage] = useState("");
  const [disbursesnackbarSeverity, setDisburseSnackbarSeverity] = useState("");
  const [rows, setrows] = useState([]);
  const [rowsReturn, setrowsReturn] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const loanid = state?.id;
  const role = localStorage.getItem("Role");
  const empcode = localStorage.getItem("employee_code");
  const handleLogout = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    setTimeout(() => {
      localStorage.removeItem("jwt_token");
      localStorage.clear();
      navigate("/");
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  const closeDisburseSnackbar = () => {
    setShowDisburseSnackbar(false);
  };
  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      flex: 1,
      maxWidth: 50,
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const formattedValue = params.value.replace(/\n/g, "<br/>");
        const inlineStyles = {
          display: "flex",
          alignItems: "center",
          lineHeight: "1.6", // Adjust the value as needed
          height: "100%", // Ensures the flex container takes full height of the cell
        };

        return (
          <Tooltip
            title={
              <span
                style={inlineStyles}
                dangerouslySetInnerHTML={{ __html: formattedValue }}
              />
            }
            arrow
          >
            <div
              style={inlineStyles}
              dangerouslySetInnerHTML={{ __html: formattedValue }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "doc_url",
      headerName: "Document URL",
      flex: 1,
      minWidth: 60,
      renderCell: (params) =>
        params.row.doc_url ? (
          <Button onClick={(e) => downloadSingleFile(params.row)}>
            Download Document
          </Button>
        ) : null,
    },
    {
      field: "is_checked",
      headerName: "System Check",
      minWidth: 150,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(event) => {
            const updatedRows = rows.map((row) =>
              row.id === params.id
                ? { ...row, is_checked: event.target.checked }
                : row
            );
            setrows(updatedRows);
            setrowsReturn(updatedRows);
          }}
          disabled={true}
        />
      ),
    },
    {
      field: "user_checked",
      headerName: "Manual Check",
      minWidth: 150,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(event) =>
            handleCheckboxChange(params.id, event.target.checked)
          }
        />
      ),
    },
  ];

  const handleCheckboxChange = (id, checked) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, user_checked: checked } : row
    );
    setrowsReturn(updatedRows);
    setrows(updatedRows);
    setAllChecked(updatedRows.every((row) => row.user_checked));
  };

  const disburseListLoading = useSelector(
    (state) => state.applicationReducer.disburseListLoading
  );

  const disburseListSuccess = useSelector(
    (state) => state.applicationReducer.disburseListSuccess
  );

  const disburseListFail = useSelector(
    (state) => state.applicationReducer.disburseListFail
  );

  const loanVerificationSuccess = useSelector(
    (state) => state.applicationReducer.loanVerificationSuccess
  );

  const loanVerificationFail = useSelector(
    (state) => state.applicationReducer.loanVerificationFail
  );

  const disburseButtonSuccess = useSelector(
    (state) => state.applicationReducer.disburseButtonSuccess
  );

  const disburseButtonFail = useSelector(
    (state) => state.applicationReducer.disburseButtonFail
  );

  const disburseButtonLoading = useSelector(
    (state) => state.applicationReducer.disburseButtonLoading
  );

  const convertBackToOriginal = (rows) => {
    const original = {};

    rows.forEach((row) => {
      if (row.id === 1) {
        original.loan_agreement = { ...row };
        delete original.loan_agreement.id; // remove the id property
      } else if (row.id === 2) {
        original.bank_account_details = { ...row };
        delete original.bank_account_details.id; // remove the id property
      } else if (row.id === 3) {
        original.disbursal_details = { ...row };
        delete original.disbursal_details.id; // remove the id property
      }
    });

    return original;
  };

  const downloadSingleFile = async (e) => {
    window.open(e.doc_url, "_blank");
  };

  // const handleAcceptClick = async () => {
  //   try {
  //     await dispatch(LoanVerification(role,empcode,remarks,"APPROVED",loanid,handleLogout));
  //   } catch (error) {
  //    console.error('Error fetching data:', error);
  //   }
  //   if(localStorage.getItem("Role")=="QDE"){
  //     navigate("/LoanDetailsQDE");
  //   }else if(localStorage.getItem("Role")=="BM"){
  //     navigate("/LoanDetailsBM");
  //   }else if(localStorage.getItem("Role")=="HO"){
  //     navigate("/LoanDetailsHO");
  //   }
  //   else{
  //     navigate("/Invalid");
  //   }
  // };

  useEffect(() => {
    setisLoading(true);
    const fetchData = async () => {
      try {
        dispatch(DisburseListAPI(loanid, handleLogout));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (disburseListSuccess.data != undefined) {
      setrowsReturn(disburseListSuccess.data);
      setisLoading(false);
      const data = disburseListSuccess.data;
      const transformedRows = [
        { id: 1, ...data.loan_agreement },
        { id: 2, ...data.bank_account_details },
        { id: 3, ...data.disbursal_details },
      ];
      setrows(transformedRows);
    }
  }, [disburseListSuccess]);

  // const handleDeclineClick = async () => {
  //   try {
  //     await dispatch(LoanVerification(role,empcode,remarks,"DECLINED",loanid,handleLogout));
  //   } catch (error) {
  //    console.error('Error fetching data:', error);
  //   }
  //   if(localStorage.getItem("Role")=="QDE"){
  //     navigate("/LoanDetailsQDE");
  //   }else if(localStorage.getItem("Role")=="BM"){
  //     navigate("/LoanDetailsBM");
  //   }else if(localStorage.getItem("Role")=="HO"){
  //     navigate("/LoanDetailsHO");
  //   }
  //   else{
  //     navigate("/Invalid");
  //   }
  // };

  const handleDisburseClick = async () => {
    setisLoading(true);
    setDisburseClicked(true);
    const original = convertBackToOriginal(rows);
    try {
      await dispatch(DisburseButton(loanid, original, handleLogout));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (disburseClicked) {
      if (disburseButtonSuccess?.data?.code == 200) {
        setDisburseSnackbarSeverity("success");
        setDisburseSnackbarMessage(disburseButtonSuccess?.data?.message);
        setShowDisburseSnackbar(true);
        setTimeout(() => {
          setisLoading(false);
          if (localStorage.getItem("Role") === "QDE") {
            navigate("/LoanDetailsQDE");
          } else if (localStorage.getItem("Role") === "BM") {
            navigate("/LoanDetailsBM");
          } else if (localStorage.getItem("Role") === "HO") {
            navigate("/LoanDetailsHO");
          } else if (localStorage.getItem("Role") == "QDEM") {
            navigate("/LoanDetailsQDEM");
          } else if (localStorage.getItem("Role") == "QDEA") {
            navigate("/LoanDetailsQDEA");
          } else {
            navigate("/Invalid");
          }
        }, 5000);
      } else {
        setDisburseSnackbarSeverity("error");
        setDisburseSnackbarMessage("Disbursal Failed");
        setShowDisburseSnackbar(true);
        setTimeout(() => {
          setisLoading(false);
          if (localStorage.getItem("Role") === "QDE") {
            navigate("/LoanDetailsQDE");
          } else if (localStorage.getItem("Role") === "BM") {
            navigate("/LoanDetailsBM");
          } else if (localStorage.getItem("Role") === "HO") {
            navigate("/LoanDetailsHO");
          } else if (localStorage.getItem("Role") == "QDEM") {
            navigate("/LoanDetailsQDEM");
          } else if (localStorage.getItem("Role") == "QDEA") {
            navigate("/LoanDetailsQDEA");
          } else {
            navigate("/Invalid");
          }
        }, 5000);
      }
    }
  }, [disburseButtonSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // dispatch(LoanDetailsList());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Grid container direction="column" sx={{ height: "100vh" }}>
        {/* Header */}
        <Grid item xs={12}>
          <CssBaseline />
          <AppBar position="fixed">
            <Headerkpm />
          </AppBar>
        </Grid>
        {/* Main content area */}
        <Grid item container sx={{ marginTop: "64px", flexGrow: 1 }}>
          {/* SideNav */}
          <Grid item xs={2}>
            <SideNav />
          </Grid>
          {/* Main content */}
          {!isLoading ? (
            <Grid item xs={10} sx={{ padding: 1.5, marginTop: "64px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginLeft: "40px" }}
              >
                Disbursement{" "}
              </Typography>
              <Box sx={{ width: "100%" }} style={{ marginTop: "2%" }}>
                <div style={{ marginTop: "1%" }}>
                  <div className="details-box" style={{ height: "68vh" }}>
                    <DataGrid
                      columns={columns}
                      rows={rows}
                      getRowHeight={(params) => {
                        return 80;
                      }}
                      style={{ height: "62vh" }}
                    />
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                    ></div>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      {/* <Button
                  sx={{
                    textTransform: "none",
                    marginTop: "20px",
                    marginRight: "20px",
                    backgroundColor: '#C87619',
                    color: 'white',
                    "&.Mui-disabled": {
                      backgroundColor: "#D3D3D3",
                      color: "gray",
                    },
                  }}
                  variant="contained"
                  className="download-button"
                  onClick={handleAcceptClick}
                  disabled={!allChecked}
                >
                  Approve
                </Button>
                <Button
                  sx={{ textTransform: "none", marginTop:"20px",marginRight:"20px" }}
                  variant="contained"
                  //disabled={allUserChecked || !checklistSubmit || !isRoleHead }   
                  className="download-button"
                  onClick={handleDeclineClick}
                  style={{
                    backgroundColor: '#C87619',
                    color: 'white',
                  }}
                >
                  Decline
                </Button> */}
                      <Button
                        sx={{
                          textTransform: "none",
                          marginTop: "20px",
                          marginRight: "20px",
                          backgroundColor: "#C87619",
                          color: "white",
                          "&.Mui-disabled": {
                            backgroundColor: "#D3D3D3",
                            color: "gray",
                          },
                        }}
                        variant="contained"
                        className="download-button"
                        onClick={handleDisburseClick}
                        disabled={!allChecked}
                      >
                        Disburse Loan
                      </Button>
                    </Grid>
                  </div>
                </div>
              </Box>
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginLeft: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          ;
        </Grid>
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <ReusableSnackbar
        open={showDisburseSnackbar}
        onClose={closeDisburseSnackbar}
        message={disbursesnackbarMessage}
        severity={disbursesnackbarSeverity}
      />
      <ErrorSnackbar
        open={disburseListFail || loanVerificationFail}
        onClose={closeSnackbar}
      />
    </>
  );
};

export default DisburseList;
