// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Width and height of the scrollbar */
::-webkit-scrollbar {
  width: 9px;
  height: 12px;
}

/* Track of the scrollbar (background) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Light gray */
  border-radius: 10px;
}

/* Thumb of the scrollbar (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Gray */
  border-radius: 10px;
  border: 2px solid #f1f1f1;
  /* Adds padding */
}

/* Hover effect on the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darker gray */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AACA,sCAAsC;AACtC;EACE,UAAU;EACV,YAAY;AACd;;AAEA,wCAAwC;AACxC;EACE,yBAAyB;EACzB,eAAe;EACf,mBAAmB;AACrB;;AAEA,gDAAgD;AAChD;EACE,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA,wCAAwC;AACxC;EACE,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n/* Width and height of the scrollbar */\n::-webkit-scrollbar {\n  width: 9px;\n  height: 12px;\n}\n\n/* Track of the scrollbar (background) */\n::-webkit-scrollbar-track {\n  background-color: #f1f1f1;\n  /* Light gray */\n  border-radius: 10px;\n}\n\n/* Thumb of the scrollbar (the draggable part) */\n::-webkit-scrollbar-thumb {\n  background-color: #888;\n  /* Gray */\n  border-radius: 10px;\n  border: 2px solid #f1f1f1;\n  /* Adds padding */\n}\n\n/* Hover effect on the scrollbar thumb */\n::-webkit-scrollbar-thumb:hover {\n  background-color: #555;\n  /* Darker gray */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
