import React from "react";
import { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import ReusableSnackbar from "../Common/timeoutSnackbar";
import ErrorSnackbar from "../Common/errorSnackbar";
import { useSelector, useDispatch } from "react-redux";
import { LoanDetailsList,LoanDetailsListLoading, LoanDetailsById, LoanAgreementPDF, GetLoanRepaymentSchedule,GetPaymentHistory  } from "../../redux/actions/LoanActions";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import PdfViewer from "../Common/pdfviewer";
import Box from "@mui/material/Box";
import SideNav from "../Common/sideNav";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "../../redux/constants/ActionTypes";
import Headerkpm from "../Common/header";
import { DataGrid } from "@mui/x-data-grid";


const LoanDetailsHO = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isloanAgreement, setisloanAgreement] = React.useState(false);
  const [loansfetched, setloansfetched] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [isLoading2, setisLoading2] = React.useState(false);
  const [base64Pdf, setbase64Pdf] = React.useState(false);

  const [isRepaymentScheduleOpen, setIsRepaymentScheduleOpen] = React.useState(false);

  const repaymentColumns = [
    {
      field: "payment_number",
      headerName: "S.No.",
      flex: 1,
      maxWidth: 100,
    },
    {
      field: "repayment_date",
      headerName: "Repayment Date",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "ewi",
      headerName: "Installment",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "principal_amount",
      headerName: "Principal Amount",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "interest",
      headerName: "Interest",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "closing_balance",
      headerName: "Closing Balance",
      flex: 1,
      maxWidth: 250,
    },
  ];
  const paymentHistoryColumns = [
    {
      field: "tran_date",
      headerName: "Transaction Date",
      flex: 1,
      maxWidth: 250,
    },
    {
      field: "transaction_mode",
      headerName: "Transaction Mode",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "tran_amount",
      headerName: "Transaction Amount",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "receipt_number",
      headerName: "Receipt Number",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1,
      maxWidth: 350,
    },
  ];

  const handleLogout = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    setTimeout(() => {
      localStorage.removeItem("jwt_token");
      localStorage.clear();
      navigate("/");
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const loanDetailsLoading = useSelector(
    (state) => state.applicationReducer.loanDetailsLoading
  );

  const loanDetailsSuccess = useSelector(
    (state) => state.applicationReducer.loanDetailsSuccess
  );

  const loanDetailsFail= useSelector(
    (state) => state.applicationReducer.loanDetailsFail
  );

  const agreementPdfSuccess = useSelector(
    (state) => state.applicationReducer.agreementPdfSuccess
  );

  const agreementPdfFail = useSelector(
    (state) => state.applicationReducer.agreementPdfFail
  );

  const repaymentScheduleSuccess = useSelector(
    (state) => state.applicationReducer.repaymentScheduleSuccess
  );

  const repaymentScheduleFail = useSelector(
    (state) => state.applicationReducer.repaymentScheduleFail
  );

  const paymentHistorySuccess = useSelector(
    (state) => state.applicationReducer.paymentHistorySuccess
  );

  const paymentHistoryFail = useSelector(
    (state) => state.applicationReducer.paymentHistoryFail
  );

  const handleagreeclick = (id) => {
    setisLoading2(true);
    try {
      dispatch(LoanAgreementPDF(id,handleLogout));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setisloanAgreement(true);
  };

  const handleScheduleClick = (id) => {
    setisLoading(true);
    setIsRepaymentScheduleOpen(true);
    try {
      dispatch(GetLoanRepaymentSchedule(id));
      dispatch(GetPaymentHistory(id));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsRepaymentScheduleOpen(true);
  }

  useEffect(() => {
    if(agreementPdfSuccess.data!=undefined)
      {
        // setisloanAgreement(true);
        setisLoading2(false);
      }
  }, [agreementPdfSuccess]);

  useEffect(() => {
    if (repaymentScheduleSuccess.data!=undefined && paymentHistorySuccess.data!=undefined){
      setisLoading(false);
    }
  })

  const handleDetailsClick = (loanid) => {
    // const fetchData = async () => {
    //   try {
    //      await dispatch(LoanDetailsById(loanid));
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    // fetchData();
    navigate("/DetailsPage", { state: { id:loanid } })
  };


  useEffect(() => {
    if(loanDetailsSuccess.data != undefined)
    {
      setloansfetched(true);
    }
  }, [loanDetailsSuccess]);

  useEffect(() => {
  const fetchData = async () => {
      try {
        dispatch(LoanDetailsList(handleLogout));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleCloseDialog = () => {
    setisloanAgreement(false);
    setIsRepaymentScheduleOpen(false);
  };
  return (
    <>
    <Grid container direction="column" sx={{ height: "100vh" }}>
      {/* Header */}
      <Grid item xs={12}>
        <CssBaseline />
        <AppBar position="fixed">
            <Headerkpm />
        </AppBar>
      </Grid>
      {/* Main content area */}
      <Grid item container sx={{ marginTop: "64px", flexGrow: 1 }}>
        {/* SideNav */}
        <Grid item xs={3}>
          <SideNav />
        </Grid>
        {/* Main content */}
        { loansfetched ? (
        <Grid item xs={9} sx={{ padding: 1.5, marginTop: '64px' }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>Loan Details</Typography>
          {loanDetailsSuccess?.data?.map((loan) => (
              <Box
                key={loan.loan_id}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "20px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginRight: "40px",
                  display: "flex",
                  flexDirection: "column",
                  borderLeft: "4px solid blue",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontWeight: "bold" }} gutterBottom>
                  {`Loan ${loan.loan_reference_id}`}
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}  gutterBottom>Application Details</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Loan Product: ${loan.loan_product}`}</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Loan Amount: ${loan.loan_amount}`}</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Loan Tenure: ${loan.loan_tenure}`}</Typography>
                <Typography sx={{ fontWeight: "bold" }} gutterBottom>Approval Details</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Eligibility Amount: ${loan.eligibility_amount}`}</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Interest Rate: ${loan.interest_rate}`}</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Tenure: ${loan.tenure}`}</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Sanctioned Amount: ${loan.sanctioned_amount}`}</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Loan Approval Status: ${loan.loan_approval_status}`}</Typography>
                <Typography sx={{ marginLeft: "20px" }} gutterBottom>{`Declined Reason: ${loan.declined_reason}`}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "auto",
                  }}
                >
                  <Button variant="outlined" onClick={() => handleScheduleClick(loan.loan_id)} style={{ marginRight: "10px"}}>
                    Repayment History
                  </Button>
                  <Button variant="outlined" onClick={() => handleagreeclick(loan.loan_id)} style={{ marginRight: "10px" }}>
                    Loan Agreement 
                  </Button>
                  <Button variant="outlined" onClick={() => handleDetailsClick(loan.loan_id)} style={{ marginRight: "10px" }}>
                    Loan Details
                  </Button>
                </Box>
              </Box>
            ))}
            <Dialog open={isRepaymentScheduleOpen} onClose={handleCloseDialog} fullScreen>
            { !isLoading ? (
              <>
              <DialogTitle>Repayment Details</DialogTitle>
              <DialogContent sx={{ flexGrow: 1, overflow: 'auto', padding: 0 }}>
                <Typography sx={{ marginTop:"20px",fontSize:"18px",marginLeft:"40px" }}>Repayment Schedule</Typography>
                <DataGrid
                  columns={repaymentColumns}
                  rows={repaymentScheduleSuccess.data}
                  getRowId={(row) => {return row?.payment_number}}
                  getRowHeight={(params) => {
                    return 60; 
                  }}
                  style={{ height: "62vh",width:"95vw", margin: '0 auto', marginTop:"10px" }}
                />

                <Typography sx={{ marginTop:"20px",fontSize:"18px",marginLeft:"40px" }}>Payment History</Typography>
                <DataGrid
                  columns={paymentHistoryColumns}
                  rows={paymentHistorySuccess.data}
                  getRowId={(row) => {return row?.receipt_number}}
                  getRowHeight={(params) => {
                    return 60; 
                  }}
                  style={{ height: "62vh",width:"95vw", margin: '0 auto', marginTop:"10px" }}
                />
                
                <DialogActions>
                <Button onClick={handleCloseDialog}
                  sx={{ textTransform: "none", marginBottom:"20px",marginRight:"20px",marginTop:"20px" }}
                  variant="contained"
                  style={{
                    backgroundColor: '#C87619',
                    color: 'white',
                    width: "20%",
                  }}
                >
                    Close
                </Button>
              </DialogActions>
              </DialogContent>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh', 
                  position: 'relative', 
                }}
              >
                <CircularProgress />
                <Button
                  onClick={handleCloseDialog}
                  sx={{
                    textTransform: 'none',
                    marginBottom: '20px',
                    marginRight: '20px',
                    marginTop: '20px',
                    position: 'absolute', 
                    bottom: '20px', 
                    right: '20px',
                    backgroundColor: '#C87619',
                    color: 'white',
                    width: '20%',
                  }}
                  variant="contained"
                >
                  Close
                </Button>
              </Box>
            )}
            </Dialog>
            <Dialog open={isloanAgreement} onClose={handleCloseDialog} fullScreen>
            { !isLoading2 ? (
              <>
              <DialogTitle>Loan Agreement</DialogTitle>
              <DialogContent sx={{ flexGrow: 1, overflow: 'auto', padding: 0 }}>
                <PdfViewer base64Pdf={agreementPdfSuccess?.data?.loan_agreement_base_64} /> 
                { agreementPdfSuccess?.data?.is_verified ? (
                  <>
                  <Typography sx={{ marginTop:"20px",fontSize:"22px",marginLeft:"20px" }}>Live Capture</Typography>
                  <img 
                    src={'data:image/jpeg;base64,'+ agreementPdfSuccess?.data?.self_photo} 
                    alt="No Picture" 
                    style={{ marginTop: '10px', width: '200px', height: '200px',marginLeft: "80px" }} 
                  />
                  </>
                ) : (
                  null
                )}
                <DialogActions>
                <Button onClick={handleCloseDialog}
                  sx={{ textTransform: "none", marginBottom:"20px",marginRight:"20px",marginTop:"20px" }}
                  variant="contained"
                  style={{
                    backgroundColor: '#C87619',
                    color: 'white',
                    width: "20%",
                  }}
                >
                    Close
                </Button>
              </DialogActions>
              </DialogContent>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh', 
                  position: 'relative', 
                }}
              >
                <CircularProgress />
                <Button
                  onClick={handleCloseDialog}
                  sx={{
                    textTransform: 'none',
                    marginBottom: '20px',
                    marginRight: '20px',
                    marginTop: '20px',
                    position: 'absolute', 
                    bottom: '20px', 
                    right: '20px',
                    backgroundColor: '#C87619',
                    color: 'white',
                    width: '20%',
                  }}
                  variant="contained"
                >
                  Close
                </Button>
              </Box>
            )}
          </Dialog>
        </Grid>
        ) : (
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                marginLeft: "500px",
              }}
            >
              <CircularProgress />
            </Box>
        )}
      </Grid>
    </Grid> 
    <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
    />
    <ErrorSnackbar
      open={loanDetailsFail || agreementPdfFail || repaymentScheduleFail || paymentHistoryFail}
      onClose={closeSnackbar}
    />
    </>
  );
};

export default LoanDetailsHO;