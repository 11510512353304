import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Ensure you have the right version of the pdf.worker.js file
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry';

const PdfViewer = ({ base64Pdf }) => {
  const pdfDataUri = `data:application/pdf;base64,${base64Pdf}`;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div style={{ height: '500px' }}>
      <Worker workerUrl={workerSrc}>
        <Viewer fileUrl={pdfDataUri} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </div>
  );
};

export default PdfViewer;

