import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  LoanDetailsById,
  LoanVerificationJLG,
} from "../../redux/actions/LoanActions";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import SideNav from "../Common/sideNav";
import Headerkpm from "../Common/header";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReusableSnackbar from "../Common/timeoutSnackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorSnackbar from "../Common/errorSnackbar";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, TextField, CircularProgress } from "@mui/material";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

const PendingActionsQDEM = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const loanid = state?.id;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isAcceptOpen, setIsAcceptOpen] = useState(false);
  const [isDeclineOpen, setIsDeclineOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTab2, setSelectedTab2] = useState(0);
  const [detailsSet, setdetailsSet] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState(false);
  const [errorBank, setErrorBank] = useState(false);
  const role = localStorage.getItem("Role");
  const empcode = localStorage.getItem("employee_code");
  const [userInfo, setUserInfo] = useState({
    voter_id: "",
    confirmVoterId: "",
    bank_account_no: "",
    confirmBankAccountNo: "",
  });

  // Handle change for VoterId
  const handleVoterIdChange = (e) => {
    setUserInfo({
      ...userInfo,
      voter_id: e.target.value,
    });
    if (e.target.value !== userInfo.confirmVoterId) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const handleConfirmVoterIdChange = (e) => {
    setUserInfo({
      ...userInfo,
      confirmVoterId: e.target.value,
    });
    if (e.target.value !== userInfo.voter_id) {
      setError(true);
    } else {
      setError(false);
    }
  };
  // Handle change for BankAccountNo
  const handleBankAccountNoChange = (e) => {
    setUserInfo({
      ...userInfo,
      bank_account_no: e.target.value,
    });
    if (e.target.value !== userInfo.confirmBankAccountNo) {
      setErrorBank(true);
    } else {
      setErrorBank(false);
    }
  };
  const handleConfirmBankChange = (e) => {
    setUserInfo({
      ...userInfo,
      confirmBankAccountNo: e.target.value,
    });
    if (e.target.value !== userInfo.bank_account_no) {
      setErrorBank(true);
    } else {
      setErrorBank(false);
    }
  };
  const handleLogout = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    setTimeout(() => {
      localStorage.removeItem("jwt_token");
      localStorage.clear();
      navigate("/");
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const base64Prefix = "data:image/jpeg;base64,";

  const loanDetailsByIdSuccess = useSelector(
    (state) => state.applicationReducer.loanDetailsByIdSuccess
  );

  const loanDetailsByIdLoading = useSelector(
    (state) => state.applicationReducer.loanDetailsByIdLoading
  );

  const loanDetailsByIdFail = useSelector(
    (state) => state.applicationReducer.loanDetailsByIdFail
  );

  const loanVerificationSuccess = useSelector(
    (state) => state.applicationReducer.loanVerificationSuccess
  );

  const loanVerificationFail = useSelector(
    (state) => state.applicationReducer.loanVerificationFail
  );

  useEffect(() => {
    if (loanDetailsByIdSuccess.data != undefined) {
      setdetailsSet(true);
      console.log(loanDetailsByIdSuccess.data);
    }
  }, [loanDetailsByIdSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setdetailsSet(false);
        await dispatch(LoanDetailsById(loanid, handleLogout));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const openAcceptDialog = () => {
    setIsAcceptOpen(true);
  };

  const handleCloseAccept = () => {
    setIsAcceptOpen(false);
  };

  const openDeclineDialog = () => {
    setIsDeclineOpen(true);
  };

  const handleCloseDecline = () => {
    setIsDeclineOpen(false);
  };

  const handleAcceptClick = async () => {
    const { voter_id, bank_account_no } = userInfo;
    const filteredUserInfo = { voter_id, bank_account_no };
    try {
      await dispatch(
        LoanVerificationJLG(
          role,
          empcode,
          remarks,
          "APPROVED",
          loanid,
          filteredUserInfo,
          handleLogout
        )
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsAcceptOpen(false);
    if (localStorage.getItem("Role") == "QDE") {
      navigate("/LoanDetailsQDE");
    } else if (localStorage.getItem("Role") == "BM") {
      navigate("/LoanDetailsBM");
    } else if (localStorage.getItem("Role") == "HO") {
      navigate("/LoanDetailsHO");
    } else if (localStorage.getItem("Role") == "QDEM") {
      navigate("/LoanDetailsQDEM");
    } else if (localStorage.getItem("Role") == "QDEA") {
      navigate("/LoanDetailsQDEA");
    } else {
      navigate("/Invalid");
    }
  };

  const handleDeclineClick = async () => {
    const { voter_id, bank_account_no } = userInfo;
    const filteredUserInfo = { voter_id, bank_account_no };
    try {
      await dispatch(
        LoanVerificationJLG(
          role,
          empcode,
          remarks,
          "DECLINED",
          loanid,
          filteredUserInfo,
          handleLogout
        )
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsDeclineOpen(true);
    if (localStorage.getItem("Role") == "QDE") {
      navigate("/LoanDetailsQDE");
    } else if (localStorage.getItem("Role") == "BM") {
      navigate("/LoanDetailsBM");
    } else if (localStorage.getItem("Role") == "HO") {
      navigate("/LoanDetailsHO");
    } else if (localStorage.getItem("Role") == "QDEM") {
      navigate("/LoanDetailsQDEM");
    } else if (localStorage.getItem("Role") == "QDEA") {
      navigate("/LoanDetailsQDEA");
    } else {
      navigate("/Invalid");
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleTabChange2 = (event, newValue) => {
    setSelectedTab2(newValue);
  };
  return (
    <>
      <Grid container direction="column" sx={{ height: "100vh" }}>
        {/* Header */}
        <Grid item xs={12}>
          <CssBaseline />
          <AppBar position="fixed">
            <Headerkpm />
          </AppBar>
        </Grid>
        {/* Main content area */}
        <Grid item container sx={{ marginTop: "64px", flexGrow: 1 }}>
          {/* SideNav */}
          <Grid item xs={2}>
            <SideNav />
          </Grid>
          {/* Main content */}
          {detailsSet ? (
            <>
              <Grid
                item
                xs={5}
                sx={{ display: "flex", paddingLeft: "10px", marginTop: "84px" }}
              >
                <Typography variant="h6">Loan Dashboard</Typography>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="tabs"
                  sx={{
                    paddingLeft: "15px",
                    "& .MuiTab-root": {
                      minWidth: "auto",
                      padding: "2px 8px",
                      minHeight: "30px",
                      fontWeight: "bold",
                      fontSize: "10px",
                      backgroundColor: "white", // Default background color for tabs
                      "&.Mui-selected": {
                        backgroundColor: "#184A90", // Blue background color for selected tab
                        color: "white", // Text color for selected tab
                        borderBottom: "none",
                      },
                      "& .Mui-selected.MuiTab-root": {
                        borderBottom: "none", // Remove bottom border for selected tab
                      },
                    },
                  }}
                >
                  <Tab label="Loan" sx={{ border: "1px solid #184A90" }} />
                  <Tab label="KYC" sx={{ border: "1px solid #184A90" }} />
                  <Tab
                    label="Credit Bureau"
                    sx={{ border: "1px solid #184A90" }}
                  />
                  <Tab label="Bank" sx={{ border: "1px solid #184A90" }} />
                  <Tab
                    label="Co-applicant"
                    sx={{ border: "1px solid #184A90" }}
                  />
                  <Tab label="Guarantor" sx={{ border: "1px solid #184A90" }} />
                </Tabs>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{ display: "flex", paddingLeft: "20px", marginTop: "84px" }}
              >
                <Typography variant="h6" sx={{ marginRight: "20px" }}>
                  Verification
                </Typography>
                <Tabs
                  value={selectedTab2}
                  onChange={handleTabChange2}
                  aria-label="tabs"
                  sx={{
                    paddingLeft: "100px",
                    "& .MuiTab-root": {
                      minWidth: "auto",
                      padding: "2px 12px",
                      minHeight: "30px",
                      fontWeight: "bold",
                      fontSize: "10px",
                      backgroundColor: "white", // Default background color for tabs
                      "&.Mui-selected": {
                        backgroundColor: "#184A90", // Blue background color for selected tab
                        color: "white", // Text color for selected tab
                        borderBottom: "none",
                      },
                      "& .Mui-selected.MuiTab-root": {
                        borderBottom: "none", // Remove bottom border for selected tab
                      },
                    },
                  }}
                >
                  <Tab label="KYC" sx={{ border: "1px solid #184A90" }} />
                  <Tab label="House" sx={{ border: "1px solid #184A90" }} />
                  <Tab label="Bank" sx={{ border: "1px solid #184A90" }} />
                  <Tab
                    label="Co-applicant"
                    sx={{ border: "1px solid #184A90" }}
                  />
                  <Tab label="Family" sx={{ border: "1px solid #184A90" }} />
                </Tabs>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5} sx={{ display: "flex", paddingLeft: "20px" }}>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    marginBottom: "20px",
                    marginRight: "40px",
                    width: "600px", // Set desired width
                    height: "450px", // Set desired height
                    borderLeft: "4px solid blue",
                    overflow: "auto",
                  }}
                >
                  {selectedTab === 0 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Loan Details
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Loan Product:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.loan_product
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Loan Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.applied_loan_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Loan Reference ID:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.loan_reference_id
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Loan Tenure:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.loan_tenure
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Approval Status:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.approval_status
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Approved Tenure:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.approved_tenure
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Center Name:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.center_name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Declined Reason:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.declined_reason
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Eligible Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.eligible_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Email ID: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.email_id
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Interest Rate:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.interest_rate
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Language: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.language
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Phone Number:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.phone_number
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Sanctioned Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.loan?.sanctioned_amount
                        }
                      </Typography>
                    </>
                  )}
                  {selectedTab === 1 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        KYC Details
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "20px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Name: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.kyc
                            ?.name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Gender: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.kyc
                            ?.gender
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Aadhaar Number:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.kyc
                            ?.aadhaar_number
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>PAN: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.kyc
                            ?.pan_number
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Phone Number:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.kyc
                            ?.phone_number
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Address: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.kyc
                            ?.address
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Marital Status:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.kyc
                            ?.marital_status
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Onboarded By:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.kyc
                            ?.onboarded_by
                        }
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Gallery>
                          <Item
                            original={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.kyc?.photo}`}
                            thumbnail={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.kyc?.photo}`}
                            width="400"
                            height="400"
                            alt={
                              loanDetailsByIdSuccess?.data?.application_details
                                ?.kyc?.photo
                                ? ""
                                : "No Picture"
                            }
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                src={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.kyc?.photo}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  cursor: "pointer",
                                }}
                                alt={
                                  loanDetailsByIdSuccess?.data
                                    ?.application_details?.kyc?.photo
                                    ? ""
                                    : "No Picture"
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Box>
                    </>
                  )}
                  {selectedTab === 2 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Credit Bureau Details
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "34px",
                          fontSize: "19px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Account Summary:{" "}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "44px",
                          fontSize: "18px",
                          marginTop: "15px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>MFI: </span>
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Max Dpd: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.mfi?.max_dpd
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Open Accounts:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.mfi?.open_accounts
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total High Credit Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.mfi?.total_high_credit_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Installment:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.mfi?.total_installment
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Outstanding Balance:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.mfi?.total_outstanding_balance
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Overdue Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.mfi?.total_overdue_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Sanction Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.mfi?.total_sanction_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Write off Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.mfi?.total_write_off_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "44px",
                          fontSize: "18px",
                          marginTop: "15px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Retail: </span>
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Max Dpd: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.retail?.max_dpd
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Open Accounts:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.retail?.open_accounts
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total High Credit Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.retail?.total_high_credit_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Installment:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.retail?.total_installment
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Outstanding Balance:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.retail?.total_outstanding_balance
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Overdue Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.retail?.total_overdue_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Sanction Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.retail?.total_sanction_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "58px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Total Write off Amount:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.account_summary?.retail?.total_write_off_amount
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "34px",
                          fontSize: "19px",
                          marginTop: "15px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Score: </span>
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "44px",
                          fontSize: "16px",
                          marginTop: "15px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>MFI: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.score?.mfi
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "44px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Retail: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.cb
                            ?.score?.retail
                        }
                      </Typography>
                    </>
                  )}
                  {selectedTab === 3 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Bank Details
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "40px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Bank Name: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.bank?.bank_name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Account Number:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.bank?.account_number
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Beneficiary Name:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.bank?.beneficiary_name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>IFSC: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.bank?.ifsc
                        }
                      </Typography>
                    </>
                  )}
                  {selectedTab === 4 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Co-applicant Details
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "20px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>First Name: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.[
                            "co-applicant"
                          ]?.first_name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Last Name: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.[
                            "co-applicant"
                          ]?.last_name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Relation: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.[
                            "co-applicant"
                          ]?.relation_code
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Aadhaar Number:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.[
                            "co-applicant"
                          ]?.aadhaar_number
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Aadhaar Details:{" "}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "60px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Name: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.[
                            "co-applicant"
                          ]?.aadhaar_details?.name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "60px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Gender: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.[
                            "co-applicant"
                          ]?.aadhaar_details?.gender
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "60px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Date of Birth:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.[
                            "co-applicant"
                          ]?.aadhaar_details?.dob
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "60px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Address: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details?.[
                            "co-applicant"
                          ]?.aadhaar_details?.address
                        }
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Gallery>
                          <Item
                            original={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.["co-applicant"]?.aadhaar_details?.photo}`}
                            thumbnail={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.["co-applicant"]?.aadhaar_details?.photo}`}
                            width="400"
                            height="400"
                            alt={
                              loanDetailsByIdSuccess?.data?.application_details
                                ?.kyc?.photo
                                ? ""
                                : "No Picture"
                            }
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                src={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.["co-applicant"]?.aadhaar_details?.photo}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  cursor: "pointer",
                                }}
                                alt={
                                  loanDetailsByIdSuccess?.data
                                    ?.application_details?.kyc?.photo
                                    ? ""
                                    : "No Picture"
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Box>
                    </>
                  )}
                  {selectedTab === 5 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Guarantor Details
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "20px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>First Name: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.guarantor?.first_name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Last Name: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.guarantor?.last_name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Relation: </span>
                        {loanDetailsByIdSuccess?.data?.application_details
                          ?.guarantor?.relation_code == "no_value_selected"
                          ? ""
                          : loanDetailsByIdSuccess?.data?.application_details
                              ?.guarantor?.relation_code}
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Aadhaar Number:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.guarantor?.aadhaar_number
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "48px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Aadhaar Details:{" "}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "60px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Name: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.guarantor?.aadhaar_details?.name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "60px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Date of Birth:{" "}
                        </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.guarantor?.aadhaar_details?.dob
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "60px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Gender: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.guarantor?.aadhaar_details?.gender
                        }
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "60px",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Address: </span>
                        {
                          loanDetailsByIdSuccess?.data?.application_details
                            ?.guarantor?.aadhaar_details?.address
                        }
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Gallery>
                          <Item
                            original={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.guarantor?.aadhaar_details?.photo}`}
                            thumbnail={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.guarantor?.aadhaar_details?.photo}`}
                            width="400"
                            height="400"
                            alt={
                              loanDetailsByIdSuccess?.data?.application_details
                                ?.kyc?.photo
                                ? ""
                                : "No Picture"
                            }
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                src={`data:image/jpeg;base64,${loanDetailsByIdSuccess?.data?.application_details?.guarantor?.aadhaar_details?.photo}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  cursor: "pointer",
                                }}
                                alt={
                                  loanDetailsByIdSuccess?.data
                                    ?.application_details?.kyc?.photo
                                    ? ""
                                    : "No Picture"
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={5} sx={{ display: "flex", paddingLeft: "20px" }}>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    marginBottom: "40px",
                    marginRight: "40px",
                    width: "600px", // Set desired width
                    height: "450px", // Set desired height
                    borderLeft: "4px solid blue",
                    overflow: "auto",
                  }}
                >
                  {selectedTab2 === 0 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Verification
                      </Typography>
                      {loanDetailsByIdSuccess?.data?.verification_details?.kyc_verifications?.map(
                        (entry, index) => (
                          <Box key={index} sx={{ marginBottom: "20px" }}>
                            <Typography
                              sx={{
                                marginLeft: "18px",
                                fontSize: "20px",
                                marginTop: "10px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {entry?.item}
                              </span>
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "16px",
                                marginLeft: "22px",
                                alignItems: "center",
                              }}
                            >
                              {entry?.verification_response?.map(
                                (entry2, index2) => (
                                  <Box
                                    key={index2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {entry2?.field_type === "photo" ? (
                                      <>
                                        <Typography>
                                          {entry2?.field_name}
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "20px",
                                            marginLeft: "80px",
                                          }}
                                        >
                                          <Gallery>
                                            <Item
                                              original={`data:image/jpeg;base64,${entry2?.field_response}`}
                                              thumbnail={`data:image/jpeg;base64,${entry2?.field_response}`}
                                              width="400"
                                              height="400"
                                              alt={
                                                loanDetailsByIdSuccess?.data
                                                  ?.application_details?.kyc
                                                  ?.photo
                                                  ? ""
                                                  : "No Picture"
                                              }
                                            >
                                              {({ ref, open }) => (
                                                <img
                                                  ref={ref}
                                                  onClick={open}
                                                  src={`data:image/jpeg;base64,${entry2?.field_response}`}
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    cursor: "pointer",
                                                  }}
                                                  alt={
                                                    loanDetailsByIdSuccess?.data
                                                      ?.application_details?.kyc
                                                      ?.photo
                                                      ? ""
                                                      : "No Picture"
                                                  }
                                                />
                                              )}
                                            </Item>
                                          </Gallery>
                                        </Box>
                                      </>
                                    ) : (
                                      <TextField
                                        label={entry2?.field_name}
                                        variant="outlined"
                                        value={entry2?.field_response}
                                        margin="normal"
                                        disabled
                                        sx={{ width: "225px" }}
                                      />
                                    )}
                                  </Box>
                                )
                              )}
                            </Box>
                          </Box>
                        )
                      )}
                    </>
                  )}
                  {selectedTab2 === 1 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Verification
                      </Typography>
                      {loanDetailsByIdSuccess?.data?.verification_details?.house_verifications?.map(
                        (entry, index) => (
                          <Box key={index} sx={{ marginBottom: "20px" }}>
                            <Typography
                              sx={{
                                marginLeft: "48px",
                                fontSize: "20px",
                                marginTop: "20px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {entry?.item}
                              </span>
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "16px",
                                marginLeft: "52px",
                                alignItems: "center",
                              }}
                            >
                              {entry?.verification_response?.map(
                                (entry2, index2) => (
                                  <Box
                                    key={index2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {entry2?.field_type === "photo" ? (
                                      <>
                                        <Typography>
                                          {entry2?.field_name}
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "20px",
                                            marginLeft: "80px",
                                          }}
                                        >
                                          <Gallery>
                                            <Item
                                              original={`data:image/jpeg;base64,${entry2.field_response}`}
                                              thumbnail={`data:image/jpeg;base64,${entry2.field_response}`}
                                              width="400"
                                              height="400"
                                              alt={
                                                loanDetailsByIdSuccess?.data
                                                  ?.application_details?.kyc
                                                  ?.photo
                                                  ? ""
                                                  : "No Picture"
                                              }
                                            >
                                              {({ ref, open }) => (
                                                <img
                                                  ref={ref}
                                                  onClick={open}
                                                  src={`data:image/jpeg;base64,${entry2.field_response}`}
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    cursor: "pointer",
                                                  }}
                                                  alt={
                                                    loanDetailsByIdSuccess?.data
                                                      ?.application_details?.kyc
                                                      ?.photo
                                                      ? ""
                                                      : "No Picture"
                                                  }
                                                />
                                              )}
                                            </Item>
                                          </Gallery>
                                        </Box>
                                      </>
                                    ) : (
                                      <TextField
                                        label={entry2?.field_name}
                                        variant="outlined"
                                        value={entry2?.field_response}
                                        margin="normal"
                                        disabled
                                        sx={{ width: "400px" }}
                                      />
                                    )}
                                  </Box>
                                )
                              )}
                            </Box>
                          </Box>
                        )
                      )}
                    </>
                  )}
                  {selectedTab2 === 2 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Verification
                      </Typography>
                      {loanDetailsByIdSuccess?.data?.verification_details?.bank_verifications?.map(
                        (entry, index) => (
                          <Box key={index} sx={{ marginBottom: "20px" }}>
                            <Typography
                              sx={{
                                marginLeft: "18px",
                                fontSize: "20px",
                                marginTop: "10px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {entry?.item}
                              </span>
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "16px",
                                marginLeft: "22px",
                                alignItems: "center",
                              }}
                            >
                              {entry?.verification_response?.map(
                                (entry2, index2) => (
                                  <Box
                                    key={index2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {entry2?.field_type === "photo" ? (
                                      <>
                                        <Typography>
                                          {entry2?.field_name}
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "20px",
                                            marginLeft: "80px",
                                          }}
                                        >
                                          <Gallery>
                                            <Item
                                              original={`data:image/jpeg;base64,${entry2?.field_response}`}
                                              thumbnail={`data:image/jpeg;base64,${entry2?.field_response}`}
                                              width="400"
                                              height="400"
                                              alt={
                                                loanDetailsByIdSuccess?.data
                                                  ?.application_details?.kyc
                                                  ?.photo
                                                  ? ""
                                                  : "No Picture"
                                              }
                                            >
                                              {({ ref, open }) => (
                                                <img
                                                  ref={ref}
                                                  onClick={open}
                                                  src={`data:image/jpeg;base64,${entry2?.field_response}`}
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    cursor: "pointer",
                                                  }}
                                                  alt={
                                                    loanDetailsByIdSuccess?.data
                                                      ?.application_details?.kyc
                                                      ?.photo
                                                      ? ""
                                                      : "No Picture"
                                                  }
                                                />
                                              )}
                                            </Item>
                                          </Gallery>
                                        </Box>
                                      </>
                                    ) : (
                                      <TextField
                                        label={entry2?.field_name}
                                        variant="outlined"
                                        value={entry2?.field_response}
                                        margin="normal"
                                        disabled
                                        sx={{ width: "225px" }}
                                      />
                                    )}
                                  </Box>
                                )
                              )}
                            </Box>
                          </Box>
                        )
                      )}
                    </>
                  )}
                  {selectedTab2 === 3 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Verification
                      </Typography>
                      {loanDetailsByIdSuccess?.data?.verification_details?.co_applicant_verifications?.map(
                        (entry, index) => (
                          <Box key={index} sx={{ marginBottom: "20px" }}>
                            <Typography
                              sx={{
                                marginLeft: "48px",
                                fontSize: "20px",
                                marginTop: "20px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {entry?.item}
                              </span>
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "16px",
                                marginLeft: "52px",
                                alignItems: "center",
                              }}
                            >
                              {entry?.verification_response?.map(
                                (entry2, index2) => (
                                  <Box
                                    key={index2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {entry2?.field_type === "photo" ? (
                                      <>
                                        <Typography>
                                          {entry2?.field_name}
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "20px",
                                            marginLeft: "80px",
                                          }}
                                        >
                                          <Gallery>
                                            <Item
                                              original={`data:image/jpeg;base64,${entry2?.field_response}`}
                                              thumbnail={`data:image/jpeg;base64,${entry2?.field_response}`}
                                              width="400"
                                              height="400"
                                              alt={
                                                loanDetailsByIdSuccess?.data
                                                  ?.application_details?.kyc
                                                  ?.photo
                                                  ? ""
                                                  : "No Picture"
                                              }
                                            >
                                              {({ ref, open }) => (
                                                <img
                                                  ref={ref}
                                                  onClick={open}
                                                  src={`data:image/jpeg;base64,${entry2?.field_response}`}
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    cursor: "pointer",
                                                  }}
                                                  alt={
                                                    loanDetailsByIdSuccess?.data
                                                      ?.application_details?.kyc
                                                      ?.photo
                                                      ? ""
                                                      : "No Picture"
                                                  }
                                                />
                                              )}
                                            </Item>
                                          </Gallery>
                                        </Box>
                                      </>
                                    ) : (
                                      <TextField
                                        label={entry2?.field_name}
                                        variant="outlined"
                                        value={entry2?.field_response}
                                        margin="normal"
                                        disabled
                                        sx={{ width: "200px" }}
                                      />
                                    )}
                                  </Box>
                                )
                              )}
                            </Box>
                          </Box>
                        )
                      )}
                    </>
                  )}
                  {selectedTab2 === 4 && (
                    <>
                      <Typography
                        sx={{ marginRight: "20px", fontSize: "20px" }}
                      >
                        Verification
                      </Typography>
                      {loanDetailsByIdSuccess?.data?.verification_details?.family_verifications?.map(
                        (entry, index) => (
                          <Box key={index} sx={{ marginBottom: "20px" }}>
                            <Typography
                              sx={{
                                marginLeft: "48px",
                                fontSize: "20px",
                                marginTop: "20px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {entry?.item}
                              </span>
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "16px",
                                marginLeft: "52px",
                                alignItems: "center",
                              }}
                            >
                              {entry?.verification_response?.map(
                                (entry2, index2) => (
                                  <Box
                                    key={index2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {entry2?.field_type === "photo" ? (
                                      <>
                                        <Typography>
                                          {entry2?.field_name}
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "20px",
                                            marginLeft: "80px",
                                          }}
                                        >
                                          <Gallery>
                                            <Item
                                              original={`data:image/jpeg;base64,${entry2?.field_response}`}
                                              thumbnail={`data:image/jpeg;base64,${entry2?.field_response}`}
                                              width="400"
                                              height="400"
                                              alt={
                                                loanDetailsByIdSuccess?.data
                                                  ?.application_details?.kyc
                                                  ?.photo
                                                  ? ""
                                                  : "No Picture"
                                              }
                                            >
                                              {({ ref, open }) => (
                                                <img
                                                  ref={ref}
                                                  onClick={open}
                                                  src={`data:image/jpeg;base64,${entry2?.field_response}`}
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    cursor: "pointer",
                                                  }}
                                                  alt={
                                                    loanDetailsByIdSuccess?.data
                                                      ?.application_details?.kyc
                                                      ?.photo
                                                      ? ""
                                                      : "No Picture"
                                                  }
                                                />
                                              )}
                                            </Item>
                                          </Gallery>
                                        </Box>
                                      </>
                                    ) : (
                                      <TextField
                                        label={entry2?.field_name}
                                        variant="outlined"
                                        value={entry2?.field_response}
                                        margin="normal"
                                        disabled
                                        sx={{ width: "200px" }}
                                      />
                                    )}
                                  </Box>
                                )
                              )}
                            </Box>
                          </Box>
                        )
                      )}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={10} sx={{ display: "flex", paddingLeft: "20px" }}>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    marginBottom: "20px",
                    marginRight: "40px",
                    width: "1200px",
                    height: "70vh",
                    borderLeft: "4px solid blue",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="h6">Bank Account No</Typography>
                          <TextField
                            label="Enter Bank Account No"
                            variant="outlined"
                            fullWidth
                            type="password"
                            margin="normal"
                            value={userInfo.bank_account_no}
                            onChange={handleBankAccountNoChange}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h6">
                            Confirm Bank Account No
                          </Typography>
                          <TextField
                            label="Enter Bank Account No"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={userInfo.confirmBankAccountNo}
                            onChange={handleConfirmBankChange}
                            error={errorBank}
                            helperText={
                              errorBank ? "Account No. do not match" : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="h6">Voter ID</Typography>
                          <TextField
                            label="Enter Voter ID"
                            variant="outlined"
                            fullWidth
                            type="password"
                            margin="normal"
                            value={userInfo.voter_id}
                            onChange={handleVoterIdChange}
                            sx={{ marginBottom: "20px" }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h6">Confirm Voter ID</Typography>
                          <TextField
                            label="Confirm Voter ID"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={userInfo.confirmVoterId}
                            onChange={handleConfirmVoterIdChange}
                            sx={{ marginBottom: "20px" }}
                            error={error}
                            helperText={error ? "Voter IDs do not match" : ""}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6">Remarks</Typography>
                      <TextField
                        label="Remarks"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(e) => handleRemarksChange(e)}
                        sx={{ height: "70px" }} // Specific dimensions
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "auto",
                    marginBottom: "50px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={openAcceptDialog}
                    disabled={error} // Disable button when error is true
                    style={{
                      marginRight: "10px",
                      backgroundColor:
                        error ||
                        errorBank ||
                        userInfo.voter_id == "" ||
                        userInfo.bank_account_no == ""
                          ? "#D3D3D3"
                          : "#C87619", // Grey color when disabled
                      color:
                        error ||
                        errorBank ||
                        userInfo.voter_id == "" ||
                        userInfo.bank_account_no == ""
                          ? "#A9A9A9"
                          : "white", // Light grey text when disabled
                      cursor:
                        error ||
                        errorBank ||
                        userInfo.voter_id == "" ||
                        userInfo.bank_account_no == ""
                          ? "not-allowed"
                          : "pointer", // Not-allowed cursor when disabled
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={openDeclineDialog}
                    style={{
                      marginRight: "40px",
                      borderColor: "orange",
                      color: "#C87619",
                    }}
                  >
                    Decline
                  </Button>
                </Box>
              </Grid>
              <Dialog
                open={isAcceptOpen}
                onClose={handleCloseAccept}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to accept?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleAcceptClick}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#C87619",
                      color: "white",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleCloseAccept}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      borderColor: "orange",
                      color: "#C87619",
                    }}
                  >
                    No
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={isDeclineOpen}
                onClose={handleCloseDecline}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to decline?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleDeclineClick}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#C87619",
                      color: "white",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleCloseDecline}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      borderColor: "orange",
                      color: "#C87619",
                    }}
                  >
                    No
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginLeft: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          ;
        </Grid>
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <ErrorSnackbar
        open={loanDetailsByIdFail || loanVerificationFail}
        onClose={closeSnackbar}
      />
    </>
  );
};

export default PendingActionsQDEM;
