import { ActionTypes } from "../constants/ActionTypes";
const intialState = {
  application: [],

  loginDetails:[],

  loanDetailsLoading: false,
  loanDetailsSuccess: [],
  loanDetailsFail: false,

  loanDetailsBranchSpecificLoading: false,
  loanDetailsBranchSpecificSuccess: [],
  loanDetailsBranchSpecificFail: false,

  loanDetailsByIdLoading: false,
  loanDetailsByIdSuccess: [],
  loanDetailsByIdFail: false,

  loanVerificationLoading: false,
  loanVerificationSuccess: [],
  loanVerificationFail: false,

  loanPendingLoading: false,
  loanPendingSuccess: [],
  loanPendingFail: false,

  agreementPdfLoading: false,
  agreementPdfSuccess: [],
  agreementPdfFail: false,

  agreementPhysicalPdfLoading: false,
  agreementPhysicalPdfSuccess: [],
  agreementPhysicalPdfFail: false,

  disburseListLoading: false,
  disburseListSuccess: [],
  disburseListFail: false,

  repaymentScheduleLoading: false,
  repaymentScheduleSuccess: [],
  repaymentScheduleFail: false,

  paymentHistoryLoading: false,
  paymentHistorySuccess: [],
  paymentHistoryFail: false,

  disburseButtonLoading: false,
  disburseButtonSuccess: [],
  disburseButtonFail: false,

  // submitDisburseLoading: false,
  // submitDisburseSuccess: [],
  // submitDisburseFail: false,
};

export const applicationReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_APPLICATION:
      return { ...state, application: payload };
    default:
      return state;

    case ActionTypes.LOGIN_DETAILS_SUCCESS:
      return {
        ...state,
        loginDetails: payload,
      };

    case ActionTypes.LOAN_DETAILS_LOADING:
      return {
        ...state,
        loanDetailsLoading: true,
        loanDetailsSuccess: [],
        loanDetailsFail: false,
      };
    case ActionTypes.LOAN_DETAILS_SUCCESS:
      return {
        ...state,
        loanDetailsLoading: false,
        loanDetailsSuccess: payload,
        loanDetailsFail: false,
      };
    case ActionTypes.LOAN_DETAILS_FAIL:
      return {
        ...state,
        loanDetailsLoading: false,
        loanDetailsSuccess: [],
        loanDetailsFail: true,
      };
    case ActionTypes.LOAN_DETAILS_BRANCH_SPECIFIC_LOADING:
      return {
        ...state,
        loanDetailsBranchSpecificLoading: true,
        loanDetailsBranchSpecificSuccess: [],
        loanDetailsBranchSpecificFail: false,
      };
    case ActionTypes.LOAN_DETAILS_BRANCH_SPECIFIC_SUCCESS:
      return {
        ...state,
        loanDetailsBranchSpecificLoading: false,
        loanDetailsBranchSpecificSuccess: payload,
        loanDetailsBranchSpecificFail: false,
      };
    case ActionTypes.LOAN_DETAILS_BRANCH_SPECIFIC_FAIL:
      return {
        ...state,
        loanDetailsBranchSpecificLoading: false,
        loanDetailsBranchSpecificSuccess: [],
        loanDetailsBranchSpecificFail: true,
      };
    case ActionTypes.LOAN_DETAILS_BY_ID_LOADING:
      return {
        ...state,
        loanDetailsByIdLoading: true,
        loanDetailsByIdSuccess: [],
        loanDetailsByIdFail: false,
      };
    case ActionTypes.LOAN_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        loanDetailsByIdLoading: false,
        loanDetailsByIdSuccess: payload,
        loanDetailsByIdFail: false,
      };
    case ActionTypes.LOAN_DETAILS_BY_ID_FAIL:
      return {
        ...state,
        loanDetailsByIdLoading: false,
        loanDetailsByIdSuccess: [],
        loanDetailsByIdFail: true,
      };
    case ActionTypes.VERIFICATION_LOAN_LOADING:
      return {
        ...state,
        loanVerificationLoading: true,
        loanVerificationSuccess: [],
        loanVerificationFail: false,
      };
    case ActionTypes.VERIFICATION_LOAN_SUCCESS:
      return {
        ...state,
        loanVerificationLoading: false,
        loanVerificationSuccess: payload,
        loanVerificationFail: false,
      };
    case ActionTypes.VERIFICATION_LOAN_FAIL:
      return {
        ...state,
        loanVerificationLoading: false,
        loanVerificationSuccess: [],
        loanVerificationFail: true,
      };
    case ActionTypes.PENDING_LOAN_LOADING:
      return {
        ...state,
        loanPendingLoading: true,
        loanPendingSuccess: [],
        loanPendingFail: false,
      };
    case ActionTypes.PENDING_LOAN_SUCCESS:
      return {
        ...state,
        loanPendingLoading: false,
        loanPendingSuccess: payload,
        loanPendingFail: false,
      };
    case ActionTypes.PENDING_LOAN_FAIL:
      return {
        ...state,
        loanPendingLoading: false,
        loanPendingSuccess: [],
        loanPendingFail: true,
      };
    case ActionTypes.LOAN_AGREEMENT_PDF_LOADING:
      return {
        ...state,
        agreementPdfLoading: true,
        agreementPdfSuccess: [],
        agreementPdfFail: false,
      };
    case ActionTypes.LOAN_AGREEMENT_PDF_SUCCESS:
      return {
        ...state,
        agreementPdfLoading: false,
        agreementPdfSuccess: payload,
        agreementPdfFail: false,
      };
    case ActionTypes.LOAN_AGREEMENT_PDF_FAIL:
      return {
        ...state,
        agreementPdfLoading: false,
        agreementPdfSuccess: [],
        agreementPdfFail: true,
      };
    case ActionTypes.LOAN_AGREEMENT_PDF_PHYSICAL_LOADING:
      return {
        ...state,
        agreementPhysicalPdfLoading: true,
        agreementPhysicalPdfSuccess: [],
        agreementPhysicalPdfFail: false,
      };
    case ActionTypes.LOAN_AGREEMENT_PDF_PHYSICAL_SUCCESS:
      return {
        ...state,
        agreementPhysicalPdfLoading: false,
        agreementPhysicalPdfSuccess: payload,
        agreementPhysicalPdfFail: false,
      };
    case ActionTypes.LOAN_AGREEMENT_PDF_PHYSICAL_FAIL:
      return {
        ...state,
        agreementPhysicalPdfLoading: false,
        agreementPhysicalPdfSuccess: [],
        agreementPhysicalPdfFail: true,
      };
    case ActionTypes.DISBURSE_LIST_LOADING:
      return {
        ...state,
        disburseListLoading: true,
        disburseListSuccess: [],
        disburseListFail: false,
      };
    case ActionTypes.DISBURSE_LIST_SUCCESS:
      return {
        ...state,
        disburseListLoading: false,
        disburseListSuccess: payload,
        disburseListFail: false,
      };
    case ActionTypes.DISBURSE_LIST_FAIL:
      return {
        ...state,
        disburseListLoading: false,
        disburseListSuccess: [],
        disburseListFail: true,
      };
    case ActionTypes.REPAYMENT_SCHEDULE_LOADING:
      return {
        ...state,
        repaymentScheduleLoading: true,
        repaymentScheduleSuccess: [],
        repaymentScheduleFail: false,
      };
    case ActionTypes.REPAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        repaymentScheduleLoading: false,
        repaymentScheduleSuccess: payload,
        repaymentScheduleFail: false,
      };
    case ActionTypes.REPAYMENT_SCHEDULE_FAIL:
      return {
        ...state,
        repaymentScheduleLoading: false,
        repaymentScheduleSuccess: [],
        repaymentScheduleFail: true,
      };
    case ActionTypes.PAYMENT_HISTORY_LOADING:
      return {
        ...state,
        paymentHistoryLoading: true,
        paymentHistorySuccess: [],
        paymentHistoryFail: false,
      };
    case ActionTypes.PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        paymentHistoryLoading: false,
        paymentHistorySuccess: payload,
        paymentHistoryFail: false,
      };
    case ActionTypes.PAYMENT_HISTORY_FAIL:
      return {
        ...state,
        paymentHistoryLoading: false,
        paymentHistorySuccess: [],
        paymentHistoryFail: true,
      };
    case ActionTypes.DISBURSE_BUTTON_LOADING:
      return {
        ...state,
        disburseButtonLoading: true,
        disburseButtonSuccess: [],
        disburseButtonFail: false,
      };
    case ActionTypes.DISBURSE_BUTTON_SUCCESS:
      return {
        ...state,
        disburseButtonLoading: false,
        disburseButtonSuccess: payload,
        disburseButtonFail: false,
      };
    case ActionTypes.DISBURSE_BUTTON_FAIL:
      return {
        ...state,
        disburseButtonLoading: false,
        disburseButtonSuccess: [],
        disburseButtonFail: true,
      };
    // case ActionTypes.SUBMIT_DISBURSE_LOADING:
    //   return {
    //     ...state,
    //     submitDisburseLoading: true,
    //     submitDisburseSuccess: [],
    //     submitDisburseFail: false,
    //   };
    // case ActionTypes.SUBMIT_DISBURSE_SUCCESS:
    //   return {
    //     ...state,
    //     submitDisburseLoading: false,
    //     submitDisburseSuccess: payload,
    //     submitDisburseFail: false,
    //   };
    // case ActionTypes.SUBMIT_DISBURSE_FAIL:
    //   return {
    //     ...state,
    //     submitDisburseLoading: false,
    //     submitDisburseSuccess: [],
    //     submitDisburseFail: true,
    //   };
  }
};