import React from "react";
import Login from "./login";
function Index() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default Index;